import { useCallback } from 'react';
import { useSetRecoilState, useRecoilValue } from 'recoil';
import { complement, filter, prop, propEq, uniqBy } from 'ramda';
import {
  isComparing,
  selectedForComparison,
} from '../../state/selectedForComparison';

const useTogglePartnershipToCompare = partnership => {
  const selectForComparison = useSetRecoilState(selectedForComparison);
  const comparingSelected = useRecoilValue(
    isComparing(partnership ? partnership.externalId : null)
  );

  return useCallback(() => {
    selectForComparison(selected => {
      if (selected.length !== 0 && selected[0].sector !== partnership.sector) {
        return selected;
      }

      const newSelected = comparingSelected
        ? filter(
            complement(propEq('externalId', prop('externalId', partnership))),
            selected
          )
        : uniqBy(prop('externalId'), [...selected, partnership]);
      return newSelected;
    });
  }, [partnership, comparingSelected, selectForComparison]);
};

export default useTogglePartnershipToCompare;
