import React from 'react';
import PropTypes from 'prop-types';
import { compose, equals, filter, map, prop, pathOr } from 'ramda';
import styled from '@emotion/styled';
import Heading from '@greenberry/salal/components/Heading';
import Icon from '@greenberry/salal/components/Icon';
import Filter from '@greenberry/salal/components/Filter';
import RadioButton from '@greenberry/salal/components/FormElements/RadioButton';
import Checkbox from '@greenberry/salal/components/FormElements/Checkbox';
import theme from '@greenberry/salal/theme';
import mq from '@greenberry/salal/utils/mediaQuery';

const Wrapper = styled.div`
  width: 100%;
  margin-top: ${theme.spacing('m')};
  position: relative;
`;

const Row = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`;

const ClearButton = styled.button`
  font-size: ${theme.fs('l')};
  line-height: ${theme.get('type.lineHeight.base')};
  color: ${theme.color('primary.1')};
  background-color: transparent;
  padding: 0;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  line-height: 0.6;
`;

const FiltersWrapper = styled.div`
  height: ${({ expanded }) => (expanded ? 'auto' : '0')};
  overflow: hidden;
  transition: height 0.2s ease-out;

  ${mq('2')} {
    height: auto;
    overflow: visible;
  }
`;

const optionsType = PropTypes.arrayOf(
  PropTypes.shape({
    label: PropTypes.string.isRequired,
    fn: PropTypes.func.isRequired,
  })
);

const activeSectorType = PropTypes.shape({
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
}).isRequired;

const getCount = (fn, name, partnerships = []) =>
  filter(compose(fn, prop(name)), partnerships).length;

const renderFilter = ({
  activeFilters,
  activeSector,
  name,
  type,
  onFilterChange,
  options,
  partnerships,
  title,
  tooltip,
}) => {
  const { value: sector } = activeSector;
  const Input = type === 'radio' ? RadioButton : Checkbox;

  return (
    <Filter
      defaultCollapsed
      title={title}
      tooltip={tooltip[sector]}
      type={type}
      key={title}
    >
      {map(
        ({ label, fn }) => (
          <Input
            key={label}
            count={getCount(fn, name, partnerships)}
            checked={equals(
              label,
              pathOr(null, [name, 'label'], activeFilters)
            )}
            onChange={() => onFilterChange({ label, fn, name })}
            id={`${name}-${label.replace(' ', '--')}`}
            label={label}
            name={title}
          />
        ),
        options[sector]
      )}
    </Filter>
  );
};

renderFilter.propTypes = {
  activeFilters: PropTypes.shape({}).isRequired,
  activeSector: activeSectorType,
  name: PropTypes.string.isRequired,
  onFilterChange: PropTypes.func.isRequired,
  options: PropTypes.shape({ 'primair-onderwijs': optionsType }),
  partnerships: PropTypes.arrayOf(PropTypes.shape({})),
  title: PropTypes.string.isRequired,
  tooltip: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['radio', 'checkbox']).isRequired,
};

const MapFilters = ({
  activeSector,
  activeFilters,
  filters,
  onFilterChange,
  partnerships,
  onFilterClear,
  expanded = false,
}) => (
  <Wrapper>
    <Heading component="h2" appearance="secondary" size="xxl">
      {partnerships.length} resultaten
    </Heading>
    <Row>
      {Object.keys(activeFilters).length > 0 && (
        <ClearButton onClick={onFilterClear}>
          <span style={{ marginRight: '1rem' }}>wis filters</span>
          <Icon type="times" size="lg" />
        </ClearButton>
      )}
    </Row>
    <FiltersWrapper expanded={expanded}>
      {map(
        filter =>
          renderFilter({
            activeSector,
            activeFilters,
            onFilterChange,
            partnerships,
            ...filter,
          }),
        filters
      )}
    </FiltersWrapper>
  </Wrapper>
);

MapFilters.propTypes = {
  activeSector: activeSectorType,
  filters: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  partnerships: PropTypes.arrayOf(PropTypes.shape({})),
  activeFilters: PropTypes.shape({}).isRequired,
  onFilterChange: PropTypes.func.isRequired,
  onFilterClear: PropTypes.func.isRequired,
  expanded: PropTypes.bool,
};

export default MapFilters;
