import { __, equals, gte, lte, T } from 'ramda';

const between = (start, end) => {
  // When end is smaller than start we assert correctly so just return false
  if (end <= start) return () => false;
  return n => {
    return n >= start && n <= end;
  };
};

const studentCountOptions = [
  {
    label: 'Alle',
    fn: T,
  },
  {
    label: '0 - < 10.000',
    fn: lte(__, 10000),
  },
  {
    label: '10.000 - < 20.000',
    fn: between(10000, 20000),
  },
  {
    label: '20.000 - < 30.000',
    fn: between(20000, 30000),
  },
  {
    label: '30.000 en meer',
    fn: gte(__, 30000),
  },
];

const reservePositionOptions = [
  { label: 'Minder dan 1%', fn: lte(__, 1) },
  { label: '1% - <5%', fn: between(1, 5) },
  { label: '5% - <10%', fn: between(5, 10) },
  { label: '10% - <20%', fn: between(10, 20) },
  { label: 'Meer dan 20%', fn: gte(__, 20) },
];

const organizationFormOptions = [
  { label: 'Alle', fn: T },
  { label: 'Vereniging', fn: equals(__, 'Vereniging') },
  { label: 'Stichting', fn: equals(__, 'Stichting') },
];

const executiveResponsibilitiesOptions = [
  {
    label: 'Alle',
    fn: T,
  },
  {
    label: 'Bestuur met een coördinator',
    fn: equals('Bestuur met een coördinator'),
  },
  {
    label: 'Bestuur met een directeur',
    fn: equals('Bestuur met een directeur'),
  },
  {
    label: 'Directeur-bestuurder of College van Bestuur',
    fn: equals('Directeur-bestuurder of College van Bestuur'),
  },
];

const supervisoryIndependenceOptions = [
  { label: 'Alle', fn: T },
  { label: 'Ja', fn: equals('Ja') },
  { label: 'Nee', fn: equals('Nee') },
];

const supervisoryDutiesOptions = [
  {
    label: 'Alle',
    fn: T,
  },
  {
    label: 'De deelnemende schoolbesturen van het samenwerkingsverband',
    fn: equals('De deelnemende schoolbesturen van het samenwerkingsverband'),
  },
  {
    label:
      'De deelnemende schoolbesturen van het samenwerkingsverband met één of meerdere onafhankelijk leden',
    fn: equals(
      'De deelnemende schoolbesturen van het samenwerkingsverband met één of meerdere onafhankelijk leden'
    ),
  },
  {
    label:
      'De deelnemende schoolbesturen van het samenwerkingsverband met een onafhankelijke voorzitter',
    fn: equals(
      'De deelnemende schoolbesturen van het samenwerkingsverband met een onafhankelijke voorzitter'
    ),
  },
  {
    label:
      'Een Raad van Toezicht volledig bestaande uit niet-deelnemende schoolbesturen',
    fn: equals(
      'Een Raad van Toezicht volledig bestaande uit niet-deelnemende schoolbesturen'
    ),
  },
];

export default [
  {
    title: 'Aantal leerlingen',
    type: 'radio',
    tooltip: {
      'primair-onderwijs':
        'Hoeveel (bekostigde) leerlingen hebben de samenwerkingsverbanden?',
      'voortgezet-onderwijs':
        'Hoeveel (bekostigde) leerlingen hebben de samenwerkingsverbanden?',
    },

    name: 'studentCount',
    options: {
      'primair-onderwijs': studentCountOptions,
      'voortgezet-onderwijs': studentCountOptions,
    },
  },
  {
    title: 'Deelname percentage speciaal onderwijs',
    type: 'radio',
    tooltip: {
      'primair-onderwijs':
        'Welk percentage van de leerlingen heeft een indicatie ontvangen voor het speciaal (basis) onderwijs',
      'voortgezet-onderwijs':
        'Welk percentage van de leerlingen heeft een indicatie ontvangen voor het speciaal voortgezet onderwijs, praktijkonderwijs of leerwegondersteunend onderwijs?',
    },
    name: 'participationRate',
    options: {
      'primair-onderwijs': [
        { label: 'Minder dan 1%', fn: lte(__, 1) },
        { label: '1% - <1,5%', fn: between(1, 1.5) },
        { label: '1,5% - <2%', fn: between(1.5, 2) },
        { label: 'Meer dan 2%', fn: gte(__, 2) },
      ],
      'voortgezet-onderwijs': [
        { label: 'Minder dan 2,5%', fn: lte(__, 2.5) },
        { label: '2,5% - <3,5%', fn: between(2.5, 3.5) },
        { label: '3,5% - <4,5%', fn: between(3.5, 4.5) },
        { label: 'Meer dan 4,5%', fn: gte(__, 4.5) },
      ],
    },
  },
  {
    title: 'Reservepositie',
    type: 'radio',
    tooltip: {
      'primair-onderwijs': 'Wat is de reservepositie?',
      'voortgezet-onderwijs': 'Wat is de reservepositie?',
    },
    name: 'reservePosition',
    options: {
      'primair-onderwijs': reservePositionOptions,
      'voortgezet-onderwijs': reservePositionOptions,
    },
  },
  {
    title: 'Organisatievorm',
    type: 'radio',
    tooltip: {
      'primair-onderwijs': 'Wat is de organisatievorm?',
      'voortgezet-onderwijs': 'Wat is de organisatievorm?',
    },
    name: 'organizationForm',
    options: {
      'primair-onderwijs': organizationFormOptions,
      'voortgezet-onderwijs': organizationFormOptions,
    },
  },
  {
    title: 'Uitvoerende verantwoordelijkheden',
    type: 'radio',
    tooltip: {
      'primair-onderwijs':
        'Bij wie liggen de uitvoerende verantwoordelijkheden?',
      'voortgezet-onderwijs':
        'Bij wie liggen de uitvoerende verantwoordelijkheden?',
    },
    name: 'executiveResponsibilities',
    options: {
      'primair-onderwijs': executiveResponsibilitiesOptions,
      'voortgezet-onderwijs': executiveResponsibilitiesOptions,
    },
  },
  {
    title: 'Onafhankelijk toezicht',
    type: 'radio',
    tooltip: {
      'primair-onderwijs': 'Is er onafhankelijkheid in het interne toezicht?',
      'voortgezet-onderwijs':
        'Is er onafhankelijkheid in het interne toezicht?',
    },
    name: 'supervisoryIndependence',
    options: {
      'primair-onderwijs': supervisoryIndependenceOptions,
      'voortgezet-onderwijs': supervisoryIndependenceOptions,
    },
  },
  {
    title: 'Intern toezicht',
    type: 'radio',
    tooltip: {
      'primair-onderwijs': 'Bij wie liggen de toezichthoudende taken?',
      'voortgezet-onderwijs': 'Bij wie liggen de toezichthoudende taken?',
    },
    name: 'supervisoryDuties',
    options: {
      'primair-onderwijs': supervisoryDutiesOptions,
      'voortgezet-onderwijs': supervisoryDutiesOptions,
    },
  },
];
